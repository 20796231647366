import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { 
    HomePage, 
    LandingPage, 
    RootLayout, 
    Zamery, 
    Rizeni, 
    Zadosti, 
    RizeniDetail, 
    ZadostiDetail,
    Stavby,
    DokumentaceScreen,
    Documentation,
    NovaZadost, 
    ZadostPovoleniStavby,
    Form01,
    Form02,
    // Form03,
    Form05,
    Form06,
    Form07,
    Form08,
    Form09,
    Form10,
    Form11,
    Form12,
    Form13,
    Form14,
    Form15,
    Form16,
    Form17,
    Form18,
    Form19,
    Unauthorized,
    UnableToChange,
    AccessibilityStatement,
    PersonalData,
    Cookies,
    PortalNewsStatement,
    ProcessError,
    DokumentaceDetail,
    LoginError,
} from '../components/index.js';
import ScrollToTop from '../components/ScrollToTop.jsx';
import UrednikLogin from '../components/Urednik/UrednikLogin.jsx';
import UrednikDocumentation from '../components/Urednik/UrednikDokumentace.jsx';
import UrednikRedirect from '../components/Urednik/UrednikRedirect.jsx';
import { TOKEN_TYPE_ISSR, TOKEN_TYPE_OZE } from '../constants/sharedConstants.js';
import useTokenType from '../components/Urednik/hooks/useTokenType.js';
import UrednikSendingError from '../components/Urednik/UrednikSendingError.jsx';
import UrednikCancel from '../components/Urednik/UrednikCancel.jsx';
import usePrevious from '../components/CustomHooks/usePrevious.jsx';

const Router = () => {
    const { token } = useContext(AuthContext);
    // Here we will decode token with jwt-decode and if user is "urednik", we will set isOfficer true
    const tokenType = useTokenType(token);
    const isOfficer = tokenType === TOKEN_TYPE_ISSR;
    const prevTokenType = usePrevious(tokenType);

    useEffect(() => {
        // window.location.reload() doesn't run from useTokenType due to unmatching regex
        // reload here if needed
        if ((tokenType === TOKEN_TYPE_OZE && prevTokenType === TOKEN_TYPE_ISSR) ||
            (tokenType === TOKEN_TYPE_ISSR && prevTokenType === TOKEN_TYPE_OZE)) {
            console.log('tokenType changed, reloading');
            window.location.reload();
        }
    }, [tokenType, prevTokenType]);

    return (
        <>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    {isOfficer ? (
                        <Route path='/' element={<RootLayout isOfficer={isOfficer} />}>
                            <Route index element={<UrednikRedirect />} />
                            <Route path='/dokumentace/:id?' element={<UrednikDocumentation isOfficer={isOfficer} />} />
                            <Route path='/cancel' index element={<UrednikCancel isOfficer={isOfficer}/>} />
                            <Route path='/sendingerror' element={<UrednikSendingError />} />
                            <Route path='*' element={<Unauthorized />} />
                        </Route>
                    ) : token ? (
                        <Route path='/' element={<RootLayout isOfficer={false}/>}>
                            <Route index element={<HomePage />} />
                            <Route path='zamery' element={<Zamery />} />
                            <Route path='rizeni' element={<Rizeni />} />
                            <Route path='zadosti' element={<Zadosti />} />
                            <Route path='rizeni/:id' element={<RizeniDetail />} />
                            <Route path='zadosti/:id' element={<ZadostiDetail />} />
                            <Route path='stavby' element={<Stavby />} />
                            <Route path='dokumentace' element={<DokumentaceScreen />} />
                            <Route path='dokumentace/:filePid/:token?' element={<DokumentaceDetail />} />
                            {/* for rozpracovane and new */}
                            <Route path='dokumentace/new/:id?' element={<Documentation />} />
                            {/* for new version */}
                            <Route path='dokumentace/version/:versionId' element={<Documentation />} />
                            <Route path='zadosti/new' element={<NovaZadost />} />
                            <Route path='zadost/form04/:id?' element={<ZadostPovoleniStavby />} />
                            <Route path='zadost/form01/:id?' element={<Form01 />} />
                            <Route path='zadost/form02/:id?' element={<Form02 />} />
                            {/* <Route path='zadost/form03/:id?' element={<Form03 />} /> */}
                            <Route path='zadost/form05/:id?' element={<Form05 />} />
                            <Route path='zadost/form06/:id?' element={<Form06 />} />
                            <Route path='zadost/form07/:id?' element={<Form07 />} />
                            <Route path='zadost/form08/:id?' element={<Form08 />} />
                            <Route path='zadost/form09/:id?' element={<Form09 />} />
                            <Route path='zadost/form10/:id?' element={<Form10 />} />
                            <Route path='zadost/form11/:id?' element={<Form11 />} />
                            <Route path='zadost/form12/:id?' element={<Form12 />} />
                            <Route path='zadost/form13/:id?' element={<Form13 />} />
                            <Route path='zadost/form14/:id?' element={<Form14 />} />
                            <Route path='zadost/form15/:id?' element={<Form15 />} />
                            <Route path='zadost/form16/:id?' element={<Form16 />} />
                            <Route path='zadost/form17/:id?' element={<Form17 />} />
                            <Route path='zadost/form18/:id?/:formType?/:receivedToken?' element={<Form18 />} />
                            <Route path='zadost/form19/:id?' element={<Form19 />} />
                            <Route path='accessibility' element={<AccessibilityStatement />} />
                            <Route path='newsOnPortal' element={<PortalNewsStatement />} />
                            <Route path='personalData' element={<PersonalData />} />
                            <Route path='cookies' element={<Cookies />} />
                            {/* add a mandatory token not to access randomly unauthorized */}
                            <Route path='/unauthorized/:token' element={<Unauthorized />} />
                            <Route path='/invalid/:token' element={<UnableToChange />} />
                            <Route path='/error' element={<ProcessError />} />
                            <Route path='*' element={<HomePage />} />
                        </Route>
                    ) : (
                        <Route path='/' element={<RootLayout />}>
                            <Route index element={<LandingPage />} />
                            <Route path='urednik' element={<UrednikLogin />} />
                            <Route path='accessibility' element={<AccessibilityStatement />} />
                            <Route path='newsOnPortal' element={<PortalNewsStatement />} />
                            <Route path='personalData' element={<PersonalData />} />
                            <Route path='cookies' element={<Cookies />} />
                            <Route path='login-error' element={<LoginError />} />
                            <Route path='*' element={<LandingPage />} />
                        </Route>
                    )}
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default Router;
