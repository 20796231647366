import { useRef, useEffect } from 'react';

const useSecondRender = () => {
    const refFirst = useRef(true);
    const refSecond = useRef(true);
    const first = refFirst.current;
    const second = refSecond.current;
    refFirst.current = false;

    useEffect(() => {
        if (first === false && second === true) {
            refSecond.current = false;
        }
    }, [first, second]);

    return second;
};

export default useSecondRender;