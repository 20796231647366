import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
// import { useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import { errorIcon } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, NewConstructionModal, AffectedConstructionsAndParcels, ZamerSelectionControls, VodniDilo } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form02.js';
import FormContext from '../../formContexts/FormContext.js';
import { handleClose, handleShow } from '../../helperFunctions/modalHelpers';
import { isPruvodniListNotYetSubmitted, shouldNotSave, spreadParcelsAndConstructions, spreadObject, checkLimitAndInsertText, hasZamerError, setUploadedDocumentsSizeFromZamer, getOngoingUploads, areUserDetailsMatching } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    newBuilding: false,
    buildingTypePurpose: '',
    changeOfFinishedBuilding: false,
    reasonChangeOfBuildingUse: false,
    newBuildingUse: '',
    buildingTemporary: false,
    setOfBuildings: false,
    techOrMarketFacility: false,
    siteFacility: false,
    maintenanceAndModificationPermission: false,
    waterworks: false,
    buildingTemporaryDuration: '',
    parcelChangeSuggestion: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    hydrogeologicalAreaName: '',
    hydrogeologicalAreaID: '',
    useNeighborFacilityReason: '',
    useNeighborFacility: false,
};

const ZamerComponent02 = ({
    documents,
    setDocuments,
    urlPath,
    setCheckbox
}) => {
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id,setUploadedBytes, setWaitingForPruvodniList, setIsDetailsMatching } = useContext(FormContext); 
    const [ show, setShow ] = useState(false);
    const [ focused, setFocused ] = useState({
        newBuildingUse: false,
        buildingTemporaryDuration: false,
        parcelChangeSuggestion: false,
        waterworksPurpose: false,
        watercourseName: false,
        watercourseId: false,
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const buildIntention = request?.buildIntention;
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const ongoingUploads = React.useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);

    const decodedToken = jwtDecode(token);
    const detailsMatching = React.useMemo(() => areUserDetailsMatching(decodedToken, request?.form), [decodedToken, request?.form]);

    useEffect(() => {
        setIsDetailsMatching(detailsMatching);
    }, [detailsMatching]);

    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [newBuilding, setNewBuilding] = useState({
        isChecked: false,
        select: '',
    });

    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        affectedBuildConstructions: [],
        affectedBuildParcels: [],
        buildConstructions: [],
        buildParcels: [],
    });
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 1000},
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    setCheckbox({
                        first: response.data?.buildApplicationForms[0]?.isException ?? false,
                    });
              
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { ...formData } } : {}) }));
                        setNewBuilding(state => ({ ...state, isChecked: intention.newBuilding, select: intention.buildingTypePurpose }));              
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: (intention.icsId || intention.title.value) ? (intention.icsId ?? '') + ((intention.icsId && intention.title.value) ? ' – ' : '') + (intention.title.value ?? '') : '', 
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    const loadedBuildApplication = response?.data?.buildApplication || {};
                    const documentations = loadedBuildApplication?.documentations ?? null;
                    if (documentations && !ongoingUploads?.length) setUploadedDocumentsSizeFromZamer(documentations, setUploadedBytes);
                    if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(response?.data ?? {}));
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        // it's not necessary to set request manually here, just use spreadParcelsAndConstructions inside zamerSelectionControls
        // if (
        //     (buildIntention?.buildParcels) ||
        //     (buildIntention?.buildConstructions)
        // ) {
        //     setParcelAndConstructionData(prev => ({
        //         ...prev,
        //         buildParcels: [...(buildIntention?.buildParcels ?? [])].map((item) => ({...item, uid: uuidv4()})),
        //         buildConstructions: [...(buildIntention?.buildConstructions ?? [])].map((item) => ({...item, uid: uuidv4()})),
        //     }));
        //     setRequest(prev => ({...prev, buildConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        // }

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest?.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if (buildIntention?.newBuilding && !buildIntention?.buildingTypePurpose.trim()) {
                err = true;
            } else if (buildIntention?.buildingTemporary &&
                (!buildIntention?.buildingTemporaryDuration?.trim() || !buildIntention?.parcelChangeSuggestion?.trim()))
            {
                err = true;
            }   
            else if(buildIntention?.reasonChangeOfBuildingUse && !buildIntention?.newBuildingUse?.trim())
            {
                err = true;
            }
            else if (buildIntention?.waterworks && !buildIntention?.waterworksPurpose)
            {
                err = true;
            }

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        setNewBuilding={setNewBuilding}
                        setParcelAndConstructionData={setParcelAndConstructionData}
                        parcelAndConstructionData={parcelAndConstructionData}
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                        documents={documents}
                        setDocuments={setDocuments}
                        urlPath={urlPath}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper'>
                        <p className='section-title p-0 mb-4'>Místo záměru</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    {/* Charakteristika záměru */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-1 section-title'>Charakteristika záměru</p>
                        <p className='p-0 mb-4 section-description'>v případě, že se žádost o vyjádření týká stavby</p>
                        <form className='d-flex flex-column p-0'>
                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={newBuilding.isChecked ?? false}
                                    onChange={(e) => { setNewBuilding(state => ({ ...state, isChecked: e.target.checked })); setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuilding: e.target.checked } })); }} />
                                Nová stavba
                            </label>
                            {newBuilding.isChecked && <Row className='row-wrapper'>
                                <Col md={6} sm={12}>
                                    <p className='p-0 mt-3 mb-1 new-construction-help-text' style={{color: error && !buildIntention?.buildingTypePurpose.trim() ? '#C52A3A' : ''}}>Druh a účel stavby</p>
                                    <label className='pe-3 id-checkbox-label w-100'>
                                        <input
                                            type="text"
                                            value={newBuilding?.select ?? ''}
                                            className='new-construction-input'
                                            disabled
                                        />
                                        <button
                                            type='button'
                                            className='d-flex justify-content-center align-items-center new-construction-button'
                                            style={{border: error && !buildIntention?.buildingTypePurpose.trim() ? 'solid 1px #C52A3A' : ''}}
                                            onClick={() => handleShow(setShow)}
                                        >
                                            Vybrat
                                        </button>
                                    </label>
                                </Col>
                                <Col md={6} sm={12} className='d-flex'>
                                    <p className='building-change align-content-end mb-md-2 mt-2 ps-0 ps-md-3'>
                                            V případě souboru staveb uveďte stavbu hlavní
                                    </p>
                                </Col>
                                <NewConstructionModal 
                                    show={show} 
                                    setShow={setShow}
                                    handleClose={handleClose}
                                    setNewBuilding={setNewBuilding} 
                                    setRequest={setRequest}
                                />
                            </Row>}
                            <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.changeOfFinishedBuilding)}
                                        onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, changeOfFinishedBuilding: e.target.checked } })); }} />
                                    Změna dokončené stavby (nástavba, přístavba, stavební úprava)
                                </label>
                            </Col>

                            <Row className={buildIntention.reasonChangeOfBuildingUse ? 'row-wrapper' : ''}>
                                <Row className='mb-2'>
                                    <Col md={6} sm={12}>
                                        <label className='pe-3 id-checkbox-label'>
                                            <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.reasonChangeOfBuildingUse)}
                                                onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, reasonChangeOfBuildingUse: e.target.checked } })); }} />
                                        Navrhuje se z důvodu změny v užívání stavby
                                        </label>
                                    </Col>
                                    <Col md={6} sm={12}>                                  
                                        <p className='building-change my-2 my-md-0 ps-0 ps-md-3'>
                                        Uveďte, zda se nástavba, přístavba nebo stavební úprava  navrhuje z důvodu změny v užívání stavby (změna ve způsobu užívání stavby, změna v provozním zařízení stavby, změna ve způsobu výroby nebo podstatném rozšíření výroby, změna v činnosti, jejíž účinky by mohly ohrozit život nebo veřejné zdraví, život a zdraví zvířat, bezpečnost nebo životní prostředí).
                                        </p>
                                    </Col> 
                                </Row>
                                {buildIntention.reasonChangeOfBuildingUse 
                                    &&
                                <>
                                    <Row className='d-flex'>
                                        <Col xs={12} md={6} className='d-flex flex-column'>
                                            <label className='select-input' style={{color: (!buildIntention?.newBuildingUse?.trim() && (error || focused.newBuildingUse)) ? '#C52A3A' : ''}}>
                                            Nový způsob užívání stavby
                                                <input
                                                    type='text'
                                                    {...(focused.newBuildingUse && { focused: 'true' })}
                                                    defaultValue={buildIntention.newBuildingUse ?? ''}
                                                    style={{border: error && !buildIntention?.newBuildingUse?.trim() ? 'solid 1px #C52A3A' : ''}}
                                                    required
                                                    onBlur={(e) => {
                                                        setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuildingUse: e.target.value } }));
                                                        handleFocused('newBuildingUse');
                                                    }}
                                                    className='mt-1 id-input2 waterworks bg-white p-2'
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    {(!buildIntention?.newBuildingUse?.trim() && (error || focused.newBuildingUse)) && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole “Nový způsob užívání stavby” není možné pokračovat.</p>
                                    </Col>}
                                </>}
                            </Row>

                            <label className='mb-2 mt-4 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.buildingTemporary)}
                                    onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporary: e.target.checked } })); }} />
                                Stavba dočasná
                            </label>
                            {buildIntention.buildingTemporary && <Row className='border p-4 row-wrapper'>
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input' style={{color: (!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.buildingTemporaryDuration)) ? '#C52A3A' : ''}}>
                                        Doba trvání dočasné stavby
                                            <input
                                                type='text'
                                                {...(focused.buildingTemporaryDuration && { focused: 'true' })}
                                                defaultValue={buildIntention.buildingTemporaryDuration ?? ''}
                                                style={{border: error && !buildIntention?.buildingTemporaryDuration?.trim() ? 'solid 1px #C52A3A' : ''}}
                                                required
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporaryDuration: e.target.value } }));
                                                    handleFocused('buildingTemporaryDuration');
                                                }}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <label className='d-flex flex-column p-0 textarea-input' style={{ color: (error && !buildIntention?.parcelChangeSuggestion?.trim()) || (focused?.parcelChangeSuggestion && !buildIntention?.parcelChangeSuggestion?.trim()) ? '#C52A3A' : '' }}>
                                    Návrh úpravy pozemku po jejím odstranění
                                    <Row className='position-relative textarea-with-count-container'>
                                        <textarea
                                            className='textarea-with-count mt-2 p-2 text-area--validation' 
                                            rows="5"
                                            required
                                            defaultValue={buildIntention.parcelChangeSuggestion ?? ''}
                                            style={{ border: error && !buildIntention?.parcelChangeSuggestion?.trim() ? 'solid 1px #C52A3A' : '' }}
                                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}                
                                            {...(focused.parcelChangeSuggestion && {focused: 'true'})}
                                            onBlur={(e) => {
                                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, parcelChangeSuggestion: e.target.value } }));
                                                handleFocused('parcelChangeSuggestion');
                                            }}
                                        />
                                        <CharCount 
                                            value={buildIntention.parcelChangeSuggestion} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                                        />
                                    </Row>
                                </label>
                            </Row>}

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.setOfBuildings)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, setOfBuildings: e.target.checked } })); }} />
                                Soubor staveb
                            </label>

                            <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.techOrMarketFacility)}
                                        onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, techOrMarketFacility: e.target.checked } })); }} />
                                    Technické zařízení nebo reklamní zařízení
                                </label>
                            </Col>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.siteFacility)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, siteFacility: e.target.checked } })); }} />
                                Zařízení staveniště
                            </label>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.waterworks)}
                                    onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworks: e.target.checked } })); }} />
                                Vodní dílo
                            </label>

                            {buildIntention.waterworks &&
                            <VodniDilo
                                buildIntention={buildIntention}
                                focused={focused}
                                request={request}
                                setRequest={setRequest}
                                handleFocused={handleFocused}
                            />}

                            <Row className='d-flex'>
                                <Col md={6} sm={12}>
                                    <label className='mb-2 id-checkbox-label'>
                                        <input type="checkbox" className='id-checkbox-input' checked={Boolean(request.buildIntention.maintenanceAndModificationPermission)}
                                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, maintenanceAndModificationPermission: e.target.checked } })); }} />
                                    Udržovací práce a stavební úpravy, které vyžadují povolení
                                    </label>
                                </Col>
                                <Col md={6} sm={12}>
                                    <p className='building-change my-2 my-md-0 ps-0 ps-md-3'>
                                    Příloha č. 2 odst. 2 písm. c) a d) stavebního zákona
                                    </p>
                                </Col>                      
                            </Row>
                        </form>
                    </Row>

                    {buildIntention.useNeighborFacility && 
                    <AffectedConstructionsAndParcels
                        setDataToAdd={setParcelAndConstructionData}
                        isAffected={true}
                        
                        urlPath={urlPath}
                        setRequest={setRequest}
                        affectedBuildParcels={parcelAndConstructionData.affectedBuildParcels}
                        affectedBuildConstructions={parcelAndConstructionData.affectedBuildConstructions}                
                    />}

                    <PozemkyTable
                        subtitle={'na kterých se má záměr uskutečnit'}
                        
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                    <StavbyTable
                        title={'Stavby'}
                        subtitle={'na kterých se má záměr uskutečnit'}
                        
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildConstructions}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                </Container>}
        </>
    );
};

ZamerComponent02.propTypes = {
    

    urlPath: PropTypes.string.isRequired,
    setCheckbox: PropTypes.func,
};

export default ZamerComponent02;
