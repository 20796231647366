import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fetchExistingDocumentation } from '../apiCalls/componentsApiCalls';
import { AuthContext } from 'react-oauth2-code-pkce';
import { DOCUMENT_FIRST } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';
import { getFormType, isPruvodniListNotYetSubmitted } from '../helperFunctions/helpers';
import { jwtDecode } from 'jwt-decode';

const DokumentaceRadioControls = ({
    urlPath,
    documents,
    setDocuments, 
    setUploadedBytes, 
    request, 
    setRequest, 
    saveChanges, 
    hasInfo = false, 
    isExternalDocumentation = false,
}) => {
    const { token } = useContext(AuthContext);
    const { setWaitingForPruvodniList } = useContext(FormContext);
    const allDocuments = Object.values(documents || {}).flat();
    const formType = getFormType(urlPath, true);
    const isRegularForm = Number(formType) > 0;
    const isDocumentation = formType === 'documentation';
    const isException = formType === '14';
    const decodedToken = jwtDecode(token);
    const intentionBppPid = decodedToken?.intentionBppPid ?? '';
    
    const handleRadioChange = ({value}) => {
        const authorizedPersonSharedLink = request?.buildApplication?.authorizedPersonSharedLink ?? '';
        // separate for now with different value and ||. Logic can change
        const documentUploadHere = value === 'here' || value === 'documentation-here';
        const documentPaperForm = value === 'office';
        const documentPreviousVersion = value === 'existing' || value === 'documentation-existing';
        const overrideKey = documentUploadHere ? 'documentUploadHere' : documentPaperForm ? 'documentPaperForm' : '';
        const canAddPreviousVersion = !!request?.buildIntention?.eszId || isDocumentation;
        
        setRequest(state => ({ 
            ...state,
            buildIntention: { 
                ...state.buildIntention, 
                documentUploadHere, 
                documentPaperForm,
                ...((documentPaperForm && !isException) && {accompanyingDocumentPdf: false, accompanyingDocumentDeveloper: true}),
                ...((documentUploadHere && !isException) && 
                    {...(authorizedPersonSharedLink 
                        ? {accompanyingDocumentPdf: false, accompanyingDocumentDeveloper: true}
                        : {accompanyingDocumentPdf: true, accompanyingDocumentDeveloper: false}
                    )}
                ),
                ...(canAddPreviousVersion && {documentPreviousVersion}),
            } 
        }));

        if (!allDocuments.filter(el => !el.subSection).length) {
            let objToSendWithoutUseEffect = {};
            objToSendWithoutUseEffect = {
                ...request, 
                buildIntention: { 
                    ...request.buildIntention,
                    documentUploadHere,
                    documentPaperForm,
                    ...((documentPaperForm && !isException) && {accompanyingDocumentPdf: false, accompanyingDocumentDeveloper: true}),
                    ...((documentUploadHere && !isException) && 
                        {...(authorizedPersonSharedLink 
                            ? {accompanyingDocumentPdf: false, accompanyingDocumentDeveloper: true}
                            : {accompanyingDocumentPdf: true, accompanyingDocumentDeveloper: false}
                        )}
                    ),
                    ...(canAddPreviousVersion && {documentPreviousVersion}),
                }
            };
            if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(request ?? {}, overrideKey));
            if (!isExternalDocumentation) saveChanges(objToSendWithoutUseEffect);
            return;
        }

        const resetObject = {};
        for (let key in documents) {
            if (Object.hasOwn(documents, String(key))) {
                if (key === DOCUMENT_FIRST) {
                    resetObject[key] = [...(request?.buildApplication?.documentations || [])].filter(el => el.section === DOCUMENT_FIRST && el.subSection);
                } else {
                    resetObject[key] = [];
                }
            }
        }

        setUploadedBytes(0);
        setDocuments(resetObject);

        if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(request ?? {}, overrideKey));
    };

    useEffect(() => {
        if (!request?.buildIntention?.documentPreviousVersion || (!isExternalDocumentation && !request?.buildIntention?.id)) {
            return;
        }
        console.log('load from bppPid');
        fetchExistingDocumentation(isExternalDocumentation ? intentionBppPid : request?.buildIntention?.bppPid, token, setDocuments, setUploadedBytes);
        
    }, [request.buildIntention?.documentPreviousVersion, request.buildIntention.id]);

    return (
        <form className='d-flex flex-column p-0 mb-4 form'>
            {isRegularForm ? (<>
                {request.buildIntention.eszId &&
                    <label className='d-flex align-items-center mb-2 label'>
                        <input type='radio' name='documentation' value='existing' className='radio' checked={Boolean(request.buildIntention?.documentPreviousVersion)}
                            onChange={(e) => { 
                                handleRadioChange(e.target);
                            }} 
                        />
                                použít nejnovější podanou verzi dokumentace
                    </label>}
                <label className='d-flex align-items-center p-0 mb-2 label'>
                    <input type='radio' name='documentation' value='here' className='radio' checked={Boolean(request.buildIntention?.documentUploadHere)}
                        onChange={(e) => { 
                            handleRadioChange(e.target);
                        }} 
                    />
                    {!request.buildIntention.eszId ? 'dokumentaci nahraji zde' : 'vložit novou verzi dokumentace'}
                </label>
                <Row>
                    <Col md={hasInfo ? 6 : 12}>
                        <label className='d-flex align-items-start pe-md-4 mb-2 mb-md-0 label'>
                            <input type='radio' name='documentation' value='office' className='radio' checked={Boolean(request.buildIntention?.documentPaperForm)}
                                onChange={(e) => { 
                                    handleRadioChange(e.target);
                                }} 
                            />
                            dokumentaci doručím na úřad v listinné podobě
                        </label>
                    </Col>
                    {hasInfo && <Col md={6}>
                        <p className='p-0 dokumentace-instruction_text'>Dokumentaci pro povolení záměru, která nemusí být zpracovaná projektantem, vloží do Evidence elektronických dokumentací stavební úřad, neučiní-li tak spolu s podáním žádosti stavebník</p>
                    </Col>}
                </Row>
            </>)
                : isDocumentation ? (<>
                    <label className='d-flex align-items-center p-0 mb-2 label'>
                        <input type='radio' name='documentation' value='documentation-here' className='radio' checked={request?.buildIntention?.documentUploadHere ?? true}
                            onChange={(e) => { 
                                handleRadioChange(e.target);
                            }} 
                        />
                        Nahrát dokumentaci
                    </label>
                    <label className='d-flex align-items-center p-0 mb-2 label'>
                        <input type='radio' name='documentation' value='documentation-existing' className='radio' checked={request?.buildIntention?.documentPreviousVersion ?? false}
                            onChange={(e) => { 
                                handleRadioChange(e.target);
                            }} 
                        />
                        Editovat poslední verzi dokumentace
                    </label>
                </>) 
                    : ''}
        </form>
    );
};

DokumentaceRadioControls.propTypes = {
    setRequest: PropTypes.func,
    request: PropTypes.object,
    hasInfo: PropTypes.bool,
    saveChanges: PropTypes.func,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
    setUploadedBytes: PropTypes.func,
};

export default DokumentaceRadioControls;