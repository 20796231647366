import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import '../../../css/formTableView.css';
import '../../../css/zadostPovoleniStavby.css';
import { AuthContext } from 'react-oauth2-code-pkce';
import { v4 as uuidv4 } from 'uuid';
import { handleAddManuallyIntoTables, updateTableValues } from '../../../helperFunctions/helpers';
import { APPROVED_STAVBY_TABLE, DRUH_KONSTRUKCE, FORM_01_INTENTION, FORM_02_INTENTION, FORM_04_INTENTION, FORM_08_INTENTION, FORM_09_INTENTION, FORM_10_INTENTION, FORM_11_INTENTION, FORM_12_INTENTION, FORM_13_INTENTION, FORM_14_INTENTION, FORM_15_INTENTION, FORM_16_INTENTION, FORM_17_INTENTION,FORM_19_INTENTION, PARCEL_TYPE_BUILD, PARCEL_TYPE_LAND, PRIPOJKA_KANALIZACNI_SITE, PRIPOJKA_PLYNU, PRIPOJKA_VODOVODU, STAVBA_MODEL, VYTAH, ZPUSOB_VYTAPENI } from '../../../constants/sharedConstants';
import FormContext from '../../../formContexts/FormContext';
import { NewConstructionModal, FoundConstructionInfo, FoundParcelInfo, DetailsFromStavbaComponent } from '../../index';
import { handleClose, handleShow } from '../../../helperFunctions/modalHelpers';
import { FORM_18_INTENTION } from '../../../constants/sharedConstants';
import usePrevious from '../../CustomHooks/usePrevious';
import { handleBrowseConstructions, handleGetCadastralAreas, handleGetCities, handleGetCityParts, handleGetConstructionDetails, handleGetParcelInStavba, handleGetParcelCity } from '../../../apiCalls/componentsApiCalls';
import useDebounce from '../../CustomHooks/useDebounce';
import { errorIcon } from '../../../assets';

const browseStateObject = {
    constructionId: '',
    flatNumber: '',
    construction: null, // foundObj to save
};

const stateObject = {
    cityName: '',
    cityList: [],
    cityPartList: [],
    cityPartCode: '',
    houseNumber: '',
    construction: null, // foundObj to save
    descriptiveNumber: true,
    evidenceNumber: false,
};

const pozemekStateObject = {
    cityName: '',
    cadastralAreaName: '',
    cityList: [],
    cadastralAreaList: [],
    parcelNum: '',
    parcelNumOpt: '',
    selectedCity: null,
    selectedCadastralArea: null,
    parcel: null, // foundObj to save
    parcelType: PARCEL_TYPE_LAND[0],
    islandParcel: true,
    isBuildingParcel: false
};

const AddStavbaModal = ({
    itemToUpdate,
    setItemToUpdate,
    category,
    showStavbaModal,
    setShowStavbaModal,
    setTableRequest,
    listData,
    setListData,
    isAffected,
}) => {
    const [{ isICSLoading, isCityLoading, isCityPartCodeLoading, isCityPartsLoading, isCadastralsLoading, isParcelLoading }, setLoadings] = useState({
        isICSLoading: false,
        isCityLoading: false,
        isCityPartCodeLoading: false,
        isCityPartsLoading: false,
        isCadastralsLoading: false,
        isParcelLoading: false,
    });
    const [helper, setHelper] = useState('');
    const [ constructionIsReadOnly, setConstructionIsReadOnly ] = useState(false);
    const [parcelNotFound, setParcelNotFound] = useState(false);
    const [constructionNotFound, setConstructionNotFound] = useState(false);
    const { token } = useContext(AuthContext);
    const { intention } = useContext(FormContext);
    // separate state for hledani podle ICS
    const [browseState, setBrowseState] = useState(browseStateObject);
    // separate state for hledani podle city
    const [state, setState] = useState(stateObject);
    // separate state adding pozemek (special for F18)
    const [pozemekState, setPozemekState] = useState(pozemekStateObject);
    const [request, setRequest] = useState(STAVBA_MODEL);
    const dynamicKey = isAffected ? 'affectedBuildConstructions' : category === APPROVED_STAVBY_TABLE ? 'approvedConstructions' : 'buildConstructions';
    const isForm10 = intention === FORM_10_INTENTION.title;
    const isForm11 = intention === FORM_11_INTENTION.title;
    const isForm12 = intention === FORM_12_INTENTION.title;
    const isForm13 = intention === FORM_13_INTENTION.title;
    const isForm14 = intention === FORM_14_INTENTION.title;
    const isForm15 = intention === FORM_15_INTENTION.title;
    const isForm16 = intention === FORM_16_INTENTION.title;
    const isForm17 = intention === FORM_17_INTENTION.title;
    const isForm19 = intention === FORM_19_INTENTION.title;
    const isForm01 = intention === FORM_01_INTENTION.title;
    const isForm02 = intention === FORM_02_INTENTION.title;
    const isForm04 = intention === FORM_04_INTENTION.title;
    const isForm08 = intention === FORM_08_INTENTION.title;
    const isForm09 = intention === FORM_09_INTENTION.title;
    const isForm16Aff = intention === FORM_16_INTENTION.title && isAffected;
    const isForm15Aff = intention === FORM_15_INTENTION.title && isAffected;
    const isForm19Aff = intention === FORM_19_INTENTION.title && isAffected;
    const isForm18 = intention === FORM_18_INTENTION.title;
    const isForm04Nei = intention === FORM_04_INTENTION.title && isAffected;
    const isForm09Nei = intention === FORM_09_INTENTION.title && isAffected;
    const shouldShowHelperText = (!isForm11 && !isForm12 && !isForm13 && !isForm14);
    const IMPORTED = itemToUpdate?.imported;
    const isICS = !IMPORTED && itemToUpdate && !itemToUpdate?.cityName && itemToUpdate?.constructionId;


    const isDisabled = Boolean(
        (browseState.construction === null && state.construction === null && (pozemekState.parcel === null || !pozemekState.cityName)) ||
        ((isForm10 || isForm14) && listData.length >= 7 && itemToUpdate === null) ||
        ((isForm11 || isForm12 || isForm13) && listData.length >= 5 && itemToUpdate === null) 
    );

    const cannotAddMore = (((isForm10 || isForm14) && listData.length >= 6) || ((isForm11 || isForm12 || isForm13) && listData.length >= 4));
    const [show, setShow] = useState(false);
    const canShowForm12or11or13Props = ((state.construction || browseState.construction || pozemekState.parcel) && (isForm12 || isForm11 || isForm13));
    const is15NotAff = !isAffected && isForm15;
    const canShowCisloJednoty = (state.construction || pozemekState.parcel || itemToUpdate?.flatNumber) && (!isForm18 && !isForm14 && !isForm10 && !isForm12 && !isForm11 && !isForm13 && !is15NotAff);

    const canSetNewBuilding = (isForm10 || isForm14 || isForm18 || isForm13 || isForm12 || isForm11);
    const [isReset, setIsReset] = useState(false);

    const [newBuilding, setNewBuilding] = useState({
        isChecked: false,
        select: '',
    });
    const previousHelper = usePrevious(helper);
    const debouncedConstructionId = useDebounce(browseState.constructionId);
    const debouncedCityName = useDebounce(state.cityName);
    const debouncedPozemekCityName = useDebounce(pozemekState.cityName);
    const debouncedPozemekParcelNum = useDebounce(pozemekState.parcelNum);
    const debouncedPozemekParcelNumOpt = useDebounce(pozemekState.parcelNumOpt);
    const debouncedStateHouseNumber = useDebounce(state.houseNumber);
    const debouncedPozemekStateCadastralAreaName = useDebounce(pozemekState.cadastralAreaName);
    const handleCancel = (helperChange = undefined) => {
        setState(stateObject);
        setPozemekState(pozemekStateObject);
        setRequest(STAVBA_MODEL);
        setBrowseState(browseStateObject);
        setItemToUpdate(null);

        if (canSetNewBuilding) {
            setNewBuilding({
                isChecked: false,
                select: '',
            });
        }

        if (helperChange === undefined) {
            setHelper('');
            setShowStavbaModal(false);
        }
    };

    const handleTableAndCancel = (idToUpdate = '') => {
        handleAddManuallyIntoTables(setTableRequest, listData, request, dynamicKey, idToUpdate);
        handleCancel();
    };
    const handleUpdateStavba = () => {
        const newData = [...listData].map(data => {
            if (data.uid === itemToUpdate?.uid) {
                return { ...data, ...request };
            }
            return data;
        });
        
        setListData(newData);
        handleTableAndCancel(itemToUpdate?.uid ?? '');
    };

    const handleAddStavba = () => {
        setListData(prev => [...prev, { ...request, uid: uuidv4() }]);
        handleTableAndCancel('');
    };

    const handleAddAnother = () => {
        handleAddStavba();
        setShowStavbaModal(true);
    };

    const spreadConstruction =  () => {
        const hasItemToUpdate = itemToUpdate?.constructionNumber || itemToUpdate?.registrationNumber;
        setHelper(isICS ? 'ics' : hasItemToUpdate ? 'address' : 'noNum');

        if (hasItemToUpdate) 
        {
            setState((prev) => ({
                ...prev,
                ...itemToUpdate,
                ...updateTableValues(itemToUpdate, state),
                uid: itemToUpdate?.uid ?? prev.uid,
                cityPartCode: itemToUpdate?.cadastralTerritoryCode ?? '',
                cityName: itemToUpdate?.cityName ?? prev.cityName,
                houseNumber: (itemToUpdate?.constructionNumber || itemToUpdate?.registrationNumber) ?? '',
                parcelType: itemToUpdate?.parcelType ?? '',
                cadastralTerritory: itemToUpdate?.cadastralTerritory ?? '',
                descriptiveNumber: itemToUpdate?.constructionNumber ?? '',
                evidenceNumber: itemToUpdate?.registrationNumber ?? '',
                construction: {
                    ...itemToUpdate?.construction,
                    cadastralTerritory: itemToUpdate?.cadastralTerritory ?? prev.construction?.cadastralTerritory,
                    parcelNumber: itemToUpdate?.parcelNumber ?? prev.construction?.parcelNumber,
                    constructionNumber: itemToUpdate?.constructionNumber ?? prev.construction?.constructionNumber,
                    registrationNumber: itemToUpdate?.registrationNumber ?? prev.construction?.registrationNumber,
                    constructionPurpose: itemToUpdate?.constructionPurpose ?? prev.construction?.constructionPurpose,
                    parcelType: itemToUpdate?.parcelType ?? prev.construction?.parcelType,
                }
            }));
        }

        if(itemToUpdate?.parcelNum?.includes('/')){
            return;
        }
        let parNum = itemToUpdate?.parcelNumber?.trim()?.split('/');
        setPozemekState(prev => ({
            ...prev,
            ...(updateTableValues(itemToUpdate, pozemekState)),
            uid: itemToUpdate?.uid ?? null,
            cityCode: itemToUpdate?.cityCode ?? null,
            cityName: itemToUpdate?.cityName ?? '',
            selectedCadastralArea: {
                code: itemToUpdate?.cadastralTerritoryCode ?? null,
                name: itemToUpdate?.cadastralTerritory ?? '',
            },
            cadastralAreaName: itemToUpdate?.cadastralTerritory ?? 'ERROR',
            parcelNum: PARCEL_TYPE_BUILD.includes(itemToUpdate?.parcelType?.trim()?.toLowerCase()) && parNum[0]?.toLowerCase()?.startsWith('st.') ? parNum[0].substr(3) : parNum[0],
            parcelNumOpt: parNum[1] ?? '',
            parcelType: itemToUpdate?.parcelType,
            parcel: {
                parcelType: itemToUpdate?.parcelType,
                parcelNumber: itemToUpdate?.parcelNumber,
                area: itemToUpdate?.area,
                landTypeCode: itemToUpdate?.landTypeCode,
                type: itemToUpdate?.type,
            },
        }));
        
        setRequest(state => ({
            ...state, ...(updateTableValues(itemToUpdate, state) || {}),
        }));

        setBrowseState(prev => ({
            ...prev,
            ...(updateTableValues(itemToUpdate, prev) || {})
        }));
        // problem is that prob due to wrong BE mapping constructionPurpose is used for druh a ucel for form18
        // while for other forms it's constructionType
        setNewBuilding(state => ({...state, select: isForm18 ? itemToUpdate?.constructionPurpose : itemToUpdate?.constructionType}));
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (constructionIsReadOnly)
            return;

        if (debouncedConstructionId?.trim() === '') {
            setLoadings((prevState) => ({
                ...prevState, isICSLoading: false
            }));
            setBrowseState(browseStateObject);
            return;
        }
        // run according to ICS
        setLoadings((prevState) => ({
            ...prevState, isICSLoading: true
        }));
        handleBrowseConstructions(debouncedConstructionId, token, setBrowseState, setLoadings, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedConstructionId]);

    useEffect(() => {
        if (browseState.flatNumber) {
            setRequest(prev => ({ ...prev, flatNumber: browseState.flatNumber }));
        }
    }, [browseState.flatNumber]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if( pozemekState?.parcelNum.includes('/')){
            return;
        }

        if ((state?.houseNumber?.trim() === '' || state?.cityPartCode === '') && !itemToUpdate) {
            setLoadings((prevState) => ({
                ...prevState, isCityPartsLoading: false
            }
            ));
            setState(prev => ({ ...prev, construction: null }));
        }

        if (state.cityPartCode && debouncedStateHouseNumber && !itemToUpdate) {
            setLoadings((prevState) => ({
                ...prevState, isCityPartsLoading: true
            }
            ));
            const requestObject = {
                cityPartCode: state.cityPartCode ?? '',
                houseNumber: debouncedStateHouseNumber ?? '',
                descriptiveNumber: state.descriptiveNumber,
                evidenceNumber: state.evidenceNumber,
            };
            handleGetConstructionDetails(
                requestObject,
                token,
                setState,
                setRequest,
                itemToUpdate ?? null,
                isReset,
                setIsReset,
                setNewBuilding,
                setConstructionNotFound,
                setLoadings,
                signal
            );
        }

        return () => {
            controller.abort();
        };
    }, [state.cityPartCode, debouncedStateHouseNumber]);

    useEffect(() => {
        if (browseState.construction) {
            setRequest(prev => ({ ...prev, ...browseState.construction }));
        }

    }, [browseState.construction]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (constructionIsReadOnly)
            return;

        const cadastralAreaName = debouncedPozemekStateCadastralAreaName.trim();

        if (cadastralAreaName === '') {
            setLoadings((prevState) => ({ ...prevState, isCadastralsLoading: false }));
            setPozemekState(prev => ({ ...prev, parcel: null, cadastralAreaList: [] }));
            return;
        }

        const pattern = /\(.*\)/;
        const withOkres = pattern.test(cadastralAreaName);
        const areaName = withOkres ? cadastralAreaName.split('(')[0].trim() : cadastralAreaName;

        const areaObj = pozemekState.cadastralAreaList?.find(el => el.name?.trim().toLowerCase() === areaName.toLowerCase()) ?? null;

        if (areaObj) {
            setPozemekState(prev => ({ ...prev, selectedCadastralArea: areaObj }));
            setLoadings((prevState) => ({ ...prevState, isCadastralsLoading: false }));
        }

        const updatedObj = { name: cadastralAreaName };

        if (!withOkres) {
            setLoadings((prevState) => ({ ...prevState, isCadastralsLoading: true }));
            handleGetCadastralAreas(updatedObj, token, setPozemekState, setLoadings, signal);
        }

        return () => {
            controller.abort();
        };
    }, [debouncedPozemekParcelNum, debouncedPozemekStateCadastralAreaName]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (constructionIsReadOnly)
            return;

        if (debouncedCityName.trim() === '') {
            setLoadings((prevState) => ({
                ...prevState,
                isCityLoading: false,
                isCityPartCodeLoading: false,
            }));
            setState(prev => ({ ...prev, cityList: [], cityPartList: [] }));
            return;
        }
        const isIncluded = debouncedCityName.includes(';');

        // TODO check with regex
        if (!isIncluded) {
            setLoadings((prevState) => ({
                ...prevState, isCityLoading: true
            }));
            handleGetCities(debouncedCityName, token, setState, setLoadings, signal);
        } else {
            const code = debouncedCityName.split(';').pop();
            const queryObject = {
                code,
                name: null,
                districtCode: null,
                districtName: null
            };
            setLoadings((prevState) => ({
                ...prevState, isCityPartCodeLoading: true
            }));
            handleGetCityParts(queryObject, token, setState, setLoadings, signal);
        }

        return () => {
            controller.abort();
        };
    }, [debouncedCityName]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        
        if (constructionIsReadOnly)
            return;

        if (debouncedPozemekCityName.trim() === '') {
            setLoadings((prevState) => ({
                ...prevState,
                isCityLoading: false,
                isCadastralsLoading: false,
            }));
            setPozemekState(prev => ({ ...prev, cityList: [] }));
            return;
        }
        // TODO check with regex
        const isIncluded = debouncedPozemekCityName.includes(';');

        if (!isIncluded) {
            setLoadings((prevState) => ({
                ...prevState, isCityLoading: true
            }));
            handleGetParcelCity(debouncedPozemekCityName, token, setPozemekState, setLoadings, signal);
        } else {
            const code = debouncedPozemekCityName.split(';').pop();
            const cityObject = pozemekState.cityList.find(el => Number(el.code) === Number(code));
            const builtRequest = {
                city: { ...cityObject }
            };
            setLoadings((prevState) => ({
                ...prevState, isCadastralsLoading: true
            }));
            handleGetCadastralAreas(builtRequest, token, setPozemekState, setLoadings, signal);
        }

        return () => {
            controller.abort();
        };
    }, [debouncedPozemekCityName]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (constructionIsReadOnly)
            return;

        if (!pozemekState.selectedCadastralArea || !debouncedPozemekParcelNum) {
            setLoadings((prevState) => ({
                ...prevState, isParcelLoading: false
            }));
            setPozemekState(prev => ({ ...prev, parcel: null }));
            return;
        }


        if( pozemekState?.parcelNum.includes('/')){
            return;
        }

        const objToSend = {
            cadastralTerritory: {
                ...pozemekState.selectedCadastralArea
            },
            rootNumber: debouncedPozemekParcelNum,
            subNumber: debouncedPozemekParcelNumOpt || null,
            islandParcel: (PARCEL_TYPE_LAND.includes(pozemekState?.parcelType?.trim()?.toLowerCase()) ? true : false) ?? true,
            isBuildingParcel: (PARCEL_TYPE_BUILD.includes(pozemekState?.parcelType?.trim()?.toLowerCase()) ? true : false) ?? false
        };

        if (debouncedPozemekParcelNum.trim() === '') {
            return;
        }

        setLoadings((prevState) => ({
            ...prevState, isParcelLoading: true
        }));

        handleGetParcelInStavba(
            objToSend,
            token,
            setPozemekState,
            setRequest,
            setIsReset,
            isReset,
            itemToUpdate ?? null,
            setNewBuilding,
            setLoadings,
            setParcelNotFound,
            signal
        );

        return () => {
            controller.abort();
        };
    }, [debouncedPozemekParcelNum, debouncedPozemekParcelNumOpt, pozemekState.selectedCadastralArea]);

    useEffect(() => {
        if (helper === '' || previousHelper === '') {
            return;
        }

        if (helper !== previousHelper) {
            handleCancel('helperChange');
        }

    }, [helper]);
     
    useEffect(() => {

        if (!itemToUpdate) {
            return;
        }
        setConstructionIsReadOnly(itemToUpdate !== null);
        spreadConstruction();

    }, []);

    return (
        <Modal show={showStavbaModal} onHide={() => handleCancel()} size='lg' centered>
            <Modal.Header className='modal-header--radius modal-background' closeButton bsPrefix='pridat-header d-flex justify-content-between'>
                <Modal.Title bsPrefix='pridat-title'>{itemToUpdate ? 'Upravit' : 'Přidat'} stavbu</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-background' bsPrefix='pridat-body'>
                {shouldShowHelperText && <p className='mb-4 pridat-text'>{itemToUpdate ? 'Vyhledat' : 'Přidat'} pomocí:</p>}
                <form className='p-0'>
                    <label className='d-flex align-items-center mb-2 label'>
                        <input type='radio' name='helper' value='ics' className='radio' checked={helper === 'ics'} disabled={constructionIsReadOnly}
                            onChange={(e) => setHelper(e.target.value)}
                        />
                        Dle IČS (Identifikačního čísla stavby)
                    </label>
                    <label className='d-flex align-items-center mb-2 label'>
                        <input type='radio' name='helper' value='address' className='radio' checked={helper === 'address'} disabled={constructionIsReadOnly}
                            onChange={(e) => setHelper(e.target.value)}
                        />
                        Dle adresy stavby dle katastru nemovitostí
                    </label>
                    {/* {!isForm10 &&  */}
                    <label className='d-flex align-items-center mb-2 label'>
                        <input type='radio' name='helper' value='noNum' className='radio' checked={helper === 'noNum'} disabled={constructionIsReadOnly}
                            onChange={(e) => setHelper(e.target.value)}
                        />
                        Bez čísla popisného/evidenčního
                    </label>
                    {/* } */}
                    {isForm18 && <label className='d-flex align-items-center mb-2 label'>
                        <input type='radio' name='helper' value='novaStavba' className='radio' checked={helper === 'novaStavba'} disabled={constructionIsReadOnly}
                            onChange={(e) => setHelper(e.target.value)}
                        />
                        Nová stavba
                    </label>}

                    {(helper === 'novaStavba' || helper === 'noNum') && <>
                        <label className='d-flex flex-column label my-4'>
                            <Row>
                                <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                    Obec (povinné)
                                    <input
                                        type="text"
                                        className={`id-input2 ${(isCityLoading && !constructionIsReadOnly) ? 'with-loading-and-search bg-white' : 'with-search'}`}
                                        value={(pozemekState.cityName) ?? ''}
                                        disabled={constructionIsReadOnly}
                                        list='city_list'
                                        onChange={(e) => setPozemekState(prev => ({ ...prev, cityName: e.target.value, cadastralAreaName: '' }))}
                                    />
                                    {pozemekState.cityList.length > 0 &&
                                        <datalist id="city_list">
                                            {pozemekState.cityList.map((city) => (
                                                <option
                                                    key={city.code}
                                                    value={`${city.name} ${city.districtName ? `(Okres ${city.districtName})` : ''}; ${city.code}`?.trim()}
                                                />
                                            ))}
                                        </datalist>}
                                </Col>
                                <Col xs={12} lg={6} className='input-help-text mt-1 mt-lg-4 ps-lg-3'>Zadání obce je povinné. Portál následně dohledá všechny názvy katastrálních území pro zadanou obec.</Col> 
                            </Row>
                        </label>

                        <label className='d-flex flex-column label my-4' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                            Katastrální území
                            <Row>
                                <Col xs={12} lg={6} className='d-flex flex-column' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                                    <select
                                        type="text"
                                        className={`id-input2 dropdown-input ${(isCadastralsLoading && !constructionIsReadOnly) ? 'with-loading' : ''}`}
                                        list='cadastral_area_list'
                                        value={(pozemekState.cadastralAreaName) ?? ''}
                                        disabled={!pozemekState.cityName || pozemekState.cadastralAreaList.length < 1 || constructionIsReadOnly}
                                        onChange={(e) => {
                                            setPozemekState(prev => ({ ...prev, cadastralAreaName: e.target.value, parcelNum: '' }));
                                        }}
                                    >
                                        <option key='' value=''>{pozemekState.cadastralAreaName ? pozemekState.cadastralAreaName : 'Vyberte'}</option>
                                        {pozemekState.cadastralAreaList.map((area) => {
                                            return <option key={area.code} value={`${area.name} ${area.districtName ? (area.districtCode === 9999 ? `(${area.districtName})` : `(Okres ${area.districtName})`) : ''}`?.trim()}>{`${area.name} ${area.districtName ? (area.districtCode === 9999 ? `(${area.districtName})` : `(Okres ${area.districtName})`) : ''}`?.trim()}</option>;
                                        })}
                                    </select>
                                </Col>
                            </Row>
                        </label>
                        <>
                            <p style={{ color: '#3B3B3B', lineHeight: '24px', letterSpacing: '0.0125em', fontSize: '1em', fontWeight: ' 400' }} className='p-0 m-0 mb-3'>Typ parcely</p>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input
                                    type='radio'
                                    name='id'
                                    className='radio'
                                    checked={PARCEL_TYPE_LAND.includes(pozemekState?.parcelType?.trim()?.toLowerCase())}
                                    disabled={constructionIsReadOnly}
                                    onChange={() => {
                                        // setPozemekState(prev => ({ ...prev, islandParcel: true, isBuildingParcel: false, parcelNum: '' }));
                                        setPozemekState(prev => ({ ...prev, parcelType: PARCEL_TYPE_LAND[0], parcelNum: '' }));
                                    }}
                                />
                                Pozemková
                            </label>
                            <label className='d-flex align-items-center mb-4 label'>
                                <input
                                    type='radio'
                                    name='id'
                                    className='radio'
                                    checked={PARCEL_TYPE_BUILD.includes(pozemekState?.parcelType?.trim()?.toLowerCase())}
                                    disabled={constructionIsReadOnly}
                                    onChange={() => {
                                        setPozemekState(prev => ({ ...prev, parcelType: PARCEL_TYPE_BUILD[0                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ], parcelNum: '' }));
                                    }}
                                />
                                Stavební
                            </label>
                        </>

                        <label className={`d-flex flex-column label ${pozemekState.parcel ? 'mb-4' : ''}`}>
                            <Row className='d-flex flex-column' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                                Parcelní číslo
                                <Col xs={12} lg={6} className='d-lg-flex'>
                                    {PARCEL_TYPE_BUILD.includes(pozemekState?.parcelType?.trim()?.toLowerCase()) &&
                                    <Col xs={12} lg={1} className='d-flex align-items-center' style={{ fontSize: '.909rem', color: '#686868', fontWeight: '400' }}>
                                        St.
                                    </Col>}
                                    <Col xs={12} lg={5} className='d-lg-flex'>
                                        <input
                                            type='text'
                                            className={`id-input2 ${(isParcelLoading && !constructionIsReadOnly) ? 'with-loading bg-white' : ''}`}
                                            value={(pozemekState.parcelNum) ?? ''}
                                            disabled={constructionIsReadOnly}
                                            style={{ width: '100%', height: '39px', border: 'solid #DDDDDD 1px', borderRadius: '3px' }}
                                            onChange={(e) => {
                                                if(e.target.value.includes('/')) {
                                                    setParcelNotFound(true);
                                                    setPozemekState(prev => ({...prev, parcelNum: e.target.value}));
                                                    setRequest(prev => ({
                                                        ...prev, parcelNumber: pozemekState?.parcelNum
                                                    }));
                                                }
                                                else if (!e.target.value.trim())
                                                {
                                                    setParcelNotFound(false);
                                                    setPozemekState(prev => ({...prev, parcelNum: e.target.value}));
                                                    setRequest(prev => ({
                                                        ...prev, parcelNumber: pozemekState?.parcelNum
                                                    }));
                                                }
                                                else {
                                                    setPozemekState(prev => ({...prev, parcelNum: e.target.value}));
                                                    setRequest(prev => ({
                                                        ...prev, parcelNumber: pozemekState?.parcelNum
                                                    }));
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} lg={1} className='d-flex align-items-center justify-content-center' style={{ fontSize: '.909rem', color: '#686868', fontWeight: '400' }}>
                                        /
                                    </Col>
                                    <Col xs={12} lg={5}>
                                        <input
                                            type='text'
                                            className={`id-input2 ${(isParcelLoading && !constructionIsReadOnly) ? 'with-loading bg-white' : ''}`}
                                            disabled={constructionIsReadOnly}
                                            value={pozemekState.parcelNumOpt ?? ''}
                                            style={{ width: '100%', height: '39px', border: 'solid #DDDDDD 1px', borderRadius: '3px' }}
                                            onChange={(e) => {
                                                setPozemekState(prev => ({ ...prev, parcelNumOpt: e.target.value }));
                                            }}
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </label>
                        {parcelNotFound &&
                            <Container fluid className='my-2'>
                                <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Zadané parcelní číslo nebylo nalezeno.</p>
                            </Container>
                        }
                        {pozemekState.parcel
                            &&
                            <FoundParcelInfo state={pozemekState} helper={helper} />
                        }
                    </>}

                    {helper === 'ics'  && <>
                        <label className='d-flex flex-column label my-4'>
                            <Container fluid >
                                <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                    IČS
                                    <input
                                        type={'text'}
                                        className={`id-input2 ${(isICSLoading && !constructionIsReadOnly) ? 'with-loading bg-white' : ''}`}
                                        list='browsed_construction_list'
                                        disabled={(constructionIsReadOnly && isICS) || IMPORTED}
                                        value={(browseState.constructionId) ?? ''}
                                        onChange={(e) => setBrowseState(prev => ({ ...prev, constructionId: e.target.value }))}
                                    />
                                </Col>
                            </Container>
                        </label>
                        <Container fluid>
                            {browseState.construction &&
                                <FoundConstructionInfo state={browseState} />}
                        </Container>
                    </>}

                    {helper === 'address' && <>
                        <label className='d-flex flex-column label my-4'>
                            <Row>
                                <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                    Obec (povinné)
                                    <input
                                        type="text"
                                        className={`id-input2 ${(isCityLoading && !constructionIsReadOnly) ? 'with-loading-and-search bg-white' : 'with-search'}`}
                                        disabled={constructionIsReadOnly}
                                        value={state.cityName ?? ''}
                                        list='city_list'
                                        onChange={(e) => setState(prev => ({ ...prev, cityName: e.target.value, cityPartCode: '' }))}
                                    />
                                    {state.cityList.length > 0 &&
                                        <datalist id="city_list">
                                            {state.cityList.map((el) =>
                                                (<option
                                                    key={el.code}
                                                    value={`${el.name} ${el.districtName ? `(Okres ${el.districtName})` : ''}; ${el.code}`}
                                                />)
                                            )}
                                        </datalist>}
                                </Col>
                                <Col xs={12} lg={6} className='input-help-text mt-1 mt-lg-4 ps-lg-3'>Zadání obce je povinné. Portál následně dohledá všechny názvy katastrálních území pro zadanou obec.</Col> 
                            </Row>
                        </label>

                        <label className='d-flex flex-column label my-4' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                            <Col xs={12} lg={6}>
                                Část obce
                                <select
                                    type='select'
                                    value={state.cityPartCode ?? ''}
                                    disabled={!state.cityName || state.cityPartList.length < 1 || constructionIsReadOnly}
                                    onChange={(e) => setState(state => ({ ...state, cityPartCode: e.target.value, houseNumber: '' }))}
                                    className={`id-input2  dropdown-input ${(isCityPartCodeLoading && !constructionIsReadOnly) ? 'with-loading' : ''}`}
                                >
                                    <option value={''}>{itemToUpdate?.cadastralTerritoryCode ? `${itemToUpdate?.cadastralTerritory} (${itemToUpdate?.cadastralTerritoryCode})` : 'Vyberte'}</option>
                                    {state.cityPartList.sort((a, b) => a.name.localeCompare(b.name)).map((el) => (
                                        <option key={el.code} value={el.code}>{el.name}</option>
                                    ))}
                                </select>
                            </Col>
                        </label>

                        <Container className='d-flex flex-column p-0 form'>
                            <p style={{ color: '#3B3B3B', lineHeight: '24px', letterSpacing: '0.0125em', fontSize: '1em', fontWeight: ' 400' }} className='p-0 m-0 mb-3'>Typ stavby</p>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input
                                    type='radio'
                                    name='id'
                                    className='radio'
                                    disabled={constructionIsReadOnly}
                                    checked={(state.descriptiveNumber) ?? state.descriptiveNumber}
                                    onChange={() => {
                                        setState(prev => ({ ...prev, descriptiveNumber: true, evidenceNumber: false, houseNumber: '' }));
                                        if (itemToUpdate) {
                                            setIsReset(true);
                                        }
                                    }}
                                />
                                Stavba s číslem popisným
                            </label>
                            <label className='d-flex align-items-center mb-4 label'>
                                <input
                                    type='radio'
                                    name='id'
                                    className='radio'
                                    disabled={constructionIsReadOnly}
                                    checked={(state.evidenceNumber)}
                                    onChange={() => {
                                        setState(prev => ({ ...prev, evidenceNumber: true, descriptiveNumber: false, houseNumber: '' }));
                                        if (itemToUpdate) {
                                            setIsReset(true);
                                        }
                                    }}
                                />
                                Stavba s číslem evidenčním
                            </label>
                        </Container>

                        <label className={`d-flex flex-column label ${constructionNotFound ? 'mb-2' : 'mb-4'}`}>
                            <Container className='d-flex flex-column flex-lg-row' fluid >
                                <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                    Číslo popisné / číslo evidenční
                                    <input
                                        type="text"
                                        disabled={constructionIsReadOnly}
                                        className={`id-input2 ${(isCityPartsLoading && !constructionIsReadOnly) ? 'with-loading bg-white' : ''}`}
                                        value={(state.houseNumber) ?? ''}
                                        // onBlur={(e) => validateCisloPopisne(e.target.value)}
                                        onChange={(e) => {
                                            setState(prev => ({ ...prev, houseNumber: e.target.value }));
                                            if (!e.target.value.trim())
                                                setConstructionNotFound(false);
                                        }}
                                    />
                                </Col>
                            </Container>

                        </label>
                        {constructionNotFound &&
                            <Container fluid className='my-2'>
                                <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Zadané číslo popisné / evidenční nebylo nalezeno.</p>
                            </Container>
                        }
                    </>}

                    {(helper === 'address' || helper === 'novaStavba' || helper === 'noNum') && <>
                        {state.construction && helper !== 'novaStavba' &&
                            <FoundConstructionInfo state={state} />
                        }

                        {
                            (canShowCisloJednoty)
                            &&
                            <label className='d-flex flex-column label my-4'>
                                <Container fluid >
                                    <Row className=''>
                                        <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                            Číslo jednotky (nepovinné)
                                            <input
                                                type={'text'}
                                                className={`id-input2 ${(!IMPORTED) ? 'bg-white' : ''}`}
                                                disabled={IMPORTED}
                                                value={(request.flatNumber) ?? ''}
                                                onChange={(e) => setRequest(prev => ({ ...prev, flatNumber: e.target.value }))}

                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </label>}

                        {canShowForm12or11or13Props &&
                            <DetailsFromStavbaComponent
                                isImported={IMPORTED}
                                newBuilding={newBuilding}
                                handleShow={handleShow}
                                show={show}
                                setShow={setShow}
                                handleClose={handleClose}
                                setNewBuilding={setNewBuilding}
                                setRequest={setRequest}
                                request={request}
                            />}

                        {(
                            (!isForm12 && !isForm11 && !isForm13) &&
                            ((state.construction && !browseState.construction) ||
                                ((helper === 'novaStavba' || helper === 'noNum') && pozemekState?.parcel))
                        )
                            &&
                            <>
                                {((!isForm16Aff && !isForm15Aff && !isForm19Aff && !isForm04Nei && !isForm09Nei))
                                    &&
                                    <label className='d-flex flex-column label my-4'>
                                        <Container fluid >
                                            <Row className=''>
                                                <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                                    IČS
                                                    <input
                                                        type={'text'}
                                                        disabled={(constructionIsReadOnly && isICS) || IMPORTED}
                                                        className={`id-input2 ${(isICSLoading && !constructionIsReadOnly) ? 'with-loading bg-white' : ''}`}
                                                        value={(request.constructionId) ?? ''}
                                                        onChange={(e) => setRequest(prev => ({ ...prev, constructionId: e.target.value }))}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={6} className='input-help-text d-flex align-items-center ps-lg-3 mt-2'>Uveďte IČS, pokud již bylo stavbě přiděleno.</Col>
                                            </Row>
                                        </Container>
                                    </label>
                                }


                                {(!isForm18 &&
                                    !isForm14 &&
                                    !isForm15 &&
                                    !isForm16 &&
                                    !isForm19 &&
                                    !isForm10 &&
                                    !isForm17 &&
                                    !isForm01 &&
                                    !isForm02 &&
                                    !isForm04 &&
                                    !isForm08 &&
                                    !isForm09 &&
                                    helper === 'noNum'
                                ) && <label className='id-checkbox-label pt-2 pb-4'>
                                    <input
                                        type='checkbox'
                                        className='id-checkbox-input'
                                        checked={request.setOfBuildings}
                                        disabled={IMPORTED}
                                        onChange={(e) => setRequest(prev => ({ ...prev, setOfBuildings: e.target.checked }))}
                                    />
                                    Je součástí souboru staveb
                                </label>}
                            </>}

                        {((state.construction || browseState.construction || pozemekState.parcel) && (isForm14 || isForm10)) && <>
                            <Row className='row-wrapper'>
                                <Col xs={12} lg={6}>
                                    <p className='py-3 new-construction-help-text'>Druh a účel stavby</p>

                                    <label className='id-checkbox-label w-100'>
                                        <input type="text" value={newBuilding?.select ?? ''} className='new-construction-input' disabled />
                                        <button type='button' className='d-flex justify-content-center align-items-center new-construction-button' disabled={IMPORTED} onClick={() => handleShow(setShow)}>Vybrat</button>
                                    </label>
                                </Col>
                                <NewConstructionModal
                                    show={show}
                                    setShow={setShow}
                                    handleClose={handleClose}
                                    setNewBuilding={setNewBuilding}
                                    setRequest={setRequest}
                                    object={'requestObject'}
                                    propertyToUpdate={'constructionType'}
                                />
                            </Row>
                        </>}

                        {((state.construction || browseState.construction || pozemekState.parcel) && (isForm14 || isForm10)) && <>
                            <label className='d-flex flex-column label mt-3 pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Účel užívání
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            disabled={IMPORTED}
                                            value={request.usePurpose ?? ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, usePurpose: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>
                        </>}

                        {(isForm14 && (state.construction || browseState.construction || pozemekState.parcel)) && <Row>
                            <label className='p-0 id-checkbox-label'>
                                <input
                                    type="checkbox"
                                    className='id-checkbox-input'
                                    disabled={IMPORTED}
                                    checked={Boolean(request.temporary)}
                                    onChange={(e) => setRequest(prev => ({ ...prev, temporary: e.target.checked }))}
                                />
                                Stavba dočasná
                            </label>
                        </Row>}

                        {(browseState.construction && !isForm18) &&
                            <label className='d-flex flex-column label pb-3'>
                                <Row className=''>
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Číslo jednotky (nepovinné)
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            disabled={IMPORTED}
                                            value={browseState.flatNumber ?? ''}
                                            onChange={(e) => setBrowseState(prev => ({ ...prev, flatNumber: e.target.value }))}
                                        />
                                    </Col>
                                </Row>
                            </label>}

                        {(isForm18 && (browseState.construction || state.construction || pozemekState.parcel)) && <>
                            <label className='id-checkbox-label pt-2 pb-4'>
                                <input
                                    type='checkbox'
                                    className='id-checkbox-input'
                                    disabled={IMPORTED}
                                    checked={request.setOfBuildings}
                                    onChange={(e) => setRequest(prev => ({ ...prev, setOfBuildings: e.target.checked }))}
                                />
                                Je součástí souboru staveb
                            </label>

                            {request.setOfBuildings &&
                                <Container className='d-flex flex-column flex-lg-row py-4' fluid>
                                    <Col>
                                        <label className='d-flex flex-column label pb-4'>
                                            <Container fluid >
                                                <Col className='d-flex flex-column modal-input-style'>
                                                    Označení v souboru staveb
                                                    <input
                                                        type={'text'}
                                                        disabled={IMPORTED}
                                                        className='id-input2'
                                                        list='browsed_construction_list'
                                                        value={request.setOfBuildingsMarking ?? ''}
                                                        onChange={(e) => setRequest(prev => ({ ...prev, setOfBuildingsMarking: e.target.value }))}
                                                    />
                                                </Col>
                                            </Container>
                                        </label>
                                    </Col>
                                    <Col className='pt-3 ps-3 mt-3 table-explanation-text-container'>
                                        <p className='table-explanation-text'>
                                            Členění je tvořeno číselnou řadou, s pěti pozicemi (x.x.x.x.x) a názvem stavebního objektu nebo provozního souboru, kdy čísla na jednotlivých pozicích mají následující pevný význam: <br className='helptext-separate' />
                                            První pozice (X.x.x.x.x.) - určuje, zda se jedná o samostatnou stavbu nebo soubor staveb, podle členění v tabulce č.1<br className='helptext-separate' />
                                            Tabulka č. 1<br className='helptext-separate' />
                                            1 - samostatná stavba<br className='helptext-separate' />
                                            2 - soubor staveb<br className='helptext-separate' />
                                            Druhá pozice (x.X.x.x.x) - určuje druh stavby hlavní, podle členění v tabulce č. 2.<br className='helptext-separate' />
                                            Třetí pozice (x.x.X.x.x) - určuje druh stavby vedlejší, podle členění v tabulce č. 2. V případě, že se nebude jednat o stavbu vedlejší, uvede se na pozici číslice „0“.<br className='helptext-separate' />
                                            Tabulka č. 2<br className='helptext-separate' />
                                            1 - objekty zařízení staveniště<br className='helptext-separate' />
                                            2 - objekty pozemních staveb<br className='helptext-separate' />
                                            3 - objekty dopravní infrastruktury<br className='helptext-separate' />
                                            4 - objekty vodních děl<br className='helptext-separate' />
                                            5 - objekty podzemních staveb<br className='helptext-separate' />
                                            6 - objekty technické infrastruktury<br className='helptext-separate' />
                                            7 - objekty úpravy území<br className='helptext-separate' />
                                            8 - volná řada objektů<br className='helptext-separate' />
                                            Čtvrtá pozice (x.x.x.X.x) - určuje stavby dopravní infrastruktury, podle členění v tabulce č. 3. Pokud se o stavbu dopravní infrastruktury nejedná uvede se na pozici číslovka „4“ (ostatní stavby).<br className='helptext-separate' />
                                            Tabulka č. 3<br className='helptext-separate' />
                                            1 - stavba pozemní komunikace<br className='helptext-separate' />
                                            2 - stavba dráhy<br className='helptext-separate' />
                                            3 - letecká stavba<br className='helptext-separate' />
                                            4 - ostatní stavby<br className='helptext-separate' />
                                            Pátá pozice (x.x.x.x.X) - určuje v případě dopravních staveb číslo stavebního objektu nebo provozního souboru podle řady uvedené v příslušném prováděcím předpise Ministerstva dopravy k dopravním stavbám. V případě ostatních staveb se uvede číslo stavebního objektu nebo provozního souboru podle dělení projektanta.
                                        </p>
                                    </Col>
                                </Container>}

                            <p className='my-4 tea-text'>TEA - technicko-ekonomické atributy budov</p>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Obestavěný prostor
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            disabled={IMPORTED}
                                            list='browsed_construction_list'
                                            value={request.enclosedArea || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, enclosedArea: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Zastavěná plocha
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            disabled={IMPORTED}
                                            value={request.buildUpArea || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, buildUpArea: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Podlahová plocha
                                        <input
                                            type={'text'}
                                            disabled={IMPORTED}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            value={request.floorArea || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, floorArea: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Počet podzemních podlaží
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            disabled={IMPORTED}
                                            list='browsed_construction_list'
                                            value={request.undergroundFloors || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, undergroundFloors: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Počet nadzemních podlaží
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            disabled={IMPORTED}
                                            value={request.overgroundFloors || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, overgroundFloors: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        <p className='py-3 new-construction-help-text'>Způsob využití</p>
                                        <label className='id-checkbox-label w-100'>
                                            <input type="text" value={newBuilding?.select ?? ''} className='new-construction-input' disabled />
                                            <button type='button' className='d-flex justify-content-center align-items-center new-construction-button' disabled={IMPORTED} onClick={() => handleShow(setShow)}>Vybrat</button>
                                        </label>
                                        
                                        <NewConstructionModal
                                            show={show}
                                            setShow={setShow}
                                            handleClose={handleClose}
                                            setNewBuilding={setNewBuilding}
                                            setRequest={setRequest}
                                            object={'requestObject'}
                                            propertyToUpdate={'constructionPurpose'}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Druh konstrukce
                                        <select type='select' value={request.constructionType || ''} onChange={(e) => setRequest(prev => ({ ...prev, constructionType: e.target.value }))} disabled={IMPORTED} className='id-input2' >
                                            {DRUH_KONSTRUKCE.map((item) => {
                                                return (
                                                    <option key={item.code + item.name} value={`${item.code} ${item.name}`}>
                                                        {item.code} {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Způsob vytápění
                                        <select type='select' value={request.heatingType || ''} onChange={(e) => setRequest(prev => ({ ...prev, heatingType: e.target.value }))} disabled={IMPORTED} className='id-input2' >
                                            {ZPUSOB_VYTAPENI.map((item) => {
                                                return (
                                                    <option key={item.code + item.name} value={`${item.code} ${item.name}`}>
                                                        {item.code} {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Přípojka vodovodu
                                        <select type='select' value={request.waterSupplyConnection || ''} onChange={(e) => setRequest(prev => ({ ...prev, waterSupplyConnection: e.target.value }))} disabled={IMPORTED} className='id-input2' >
                                            {PRIPOJKA_VODOVODU.map((item) => {
                                                return (
                                                    <option key={item.code + item.name} value={`${item.code} ${item.name}`}>
                                                        {item.code} {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Přípojka kanalizační sítě
                                        <select type='select' value={request.sewerNetworkConnection || ''} onChange={(e) => setRequest(prev => ({ ...prev, sewerNetworkConnection: e.target.value }))} disabled={IMPORTED} className='id-input2' >
                                            {PRIPOJKA_KANALIZACNI_SITE.map((item) => {
                                                return (
                                                    <option key={item.code + item.name} value={`${item.code} ${item.name}`}>
                                                        {item.code} {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Přípojka plynu
                                        <select type='select' value={request.gasConnection || ''} onChange={(e) => setRequest(prev => ({ ...prev, gasConnection: e.target.value }))} disabled={IMPORTED} className='id-input2' >
                                            {PRIPOJKA_PLYNU.map((item) => {
                                                return (
                                                    <option key={item.code + item.name} value={`${item.code} ${item.name}`}>
                                                        {item.code} {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-2'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Výtah
                                        <select type='select' value={request.elevator || ''} onChange={(e) => setRequest(prev => ({ ...prev, elevator: e.target.value }))} disabled={IMPORTED} className='id-input2' >
                                            {VYTAH.map((item) => {
                                                return (
                                                    <option key={item.code + item.name} value={`${item.code} ${item.name}`}>
                                                        {item.code} {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Container>
                            </label>

                            <p className='my-4 tea-text'>Atributy stavby pro stanovení podmínek napojení a provádění činností v ochranných a bezpečnostních pásmech dopravní a technické infrastruktury</p>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Hloubka stavby
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            disabled={IMPORTED}
                                            value={request.depth || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, depth: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Výška stavby
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            disabled={IMPORTED}
                                            value={request.height || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, height: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Předpokládaná kapacita počtu osob ve stavbě
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            disabled={IMPORTED}
                                            value={request.estimatedPersonCapacity || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, estimatedPersonCapacity: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>

                            <label className='d-flex flex-column label pb-4'>
                                <Container fluid >
                                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                                        Plánovaný začátek a konec realizace stavby
                                        <input
                                            type={'text'}
                                            className='id-input2'
                                            list='browsed_construction_list'
                                            disabled={IMPORTED}
                                            value={request.estimatedStartEnd || ''}
                                            onChange={(e) => setRequest(prev => ({ ...prev, estimatedStartEnd: e.target.value }))}
                                        />
                                    </Col>
                                </Container>
                            </label>
                        </>}
                    </>}
                </form>
            </Modal.Body>
            <Modal.Footer className='modal-footer--radius modal-background' bsPrefix='d-flex px-sm-5 modal-footer-custom' style={{ paddingTop: '2.5rem' }}>
                <Row className='button-wrapper'>
                    {!IMPORTED && !isICS &&
                        <Col className='d-flex flex-column flex-lg-row button-wrapper--inner' xs={12} lg={6}>
                            <button className={`mb-3 mb-lg-0 me-lg-3 modal-button modal-button--filled ${isDisabled ? 'disabled-filled' : ''}`}
                                onClick={() => { itemToUpdate ? handleUpdateStavba() : handleAddStavba(); }}
                                disabled={isDisabled}>
                                {itemToUpdate ? 'Upravit' : 'Přidat'}
                            </button>
                            {!itemToUpdate && <button className={`modal-button ${(isDisabled || cannotAddMore) ? 'disabled-unfilled' : ''}`}
                                onClick={() => handleAddAnother()}
                                disabled={(isDisabled || cannotAddMore)}>
                                Přidat další
                            </button>}
                        </Col>
                    }
                    <Col className='d-flex justify-content-end'>
                        <button className='modal-button' onClick={() => handleCancel()}>{(IMPORTED || isICS) ? 'Zavřít náhled' : 'Zrušit'}</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

AddStavbaModal.propTypes = {
    itemToUpdate: PropTypes.object,
    setItemToUpdate: PropTypes.func,

    category: PropTypes.string,
    showStavbaModal: PropTypes.bool.isRequired,
    setShowStavbaModal: PropTypes.func.isRequired,
    setTableRequest: PropTypes.func,
    setListData: PropTypes.func,
    listData: PropTypes.arrayOf(Object),
    isAffected: PropTypes.bool,
};

export default AddStavbaModal;
