import React, { useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/kontrolaComponent.css';
import { errorIcon, file } from '../../assets/index';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext.js';
import { FileUpload, UploadedAttachments } from '../../components/index.js';
import { extractExtensionName, extractFileName, getNewAbortController, getUrl, shouldNotSave, spreadUploadedDocuments } from '../../helperFunctions/helpers.js';
import { formSave, getFormById } from '../../apiCalls/formApiCalls.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { DOCUMENT_FIRST } from '../../constants/sharedConstants.js';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import useSecondRender from '../CustomHooks/useSecondRender.jsx';

const KontrolaComponent18 = ({ 
    documents,
    setDocuments,
    urlPath,
    receivedToken,
    buttonRef,
}) => {
    const { token } = useContext(AuthContext);
    const { setUploadedBytes, setUploadError, uploadError, setStagesArr, intention, stepValue, id, stagesArr, validationRef } = useContext(FormContext); 
    const [request, setRequest] = useState(
        {
            'applicationId': id,
            'buildApplication': {
                'documentations': []
            },
        }
    );
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const documentations = request?.buildApplication?.documentations;
    const documentMissing = useMemo(() => (!Array.isArray(documentations) || !documentations.some((el) => el.subSection === 'cover_letter_pdf')), [documentations]);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const allDocuments = useMemo(() => Object.values(documents || {})?.flat(), [documents]);
    const documentsToUpdate = useMemo(() => {
        return [...(allDocuments || [])].filter(fileObj => 
            (!fileObj.blobFile && !fileObj.abortController && !fileObj.message && !fileObj.hasError));
    }, [allDocuments]);
    const existingFilesToUpdate = useMemo(() => documentsToUpdate.filter(el => el.existing), [documentsToUpdate]);
    const newFilesToUpdate = useMemo(() => documentsToUpdate.filter(el => !el.existing), [documentsToUpdate]);
    const secondRender = useSecondRender();
    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source, receivedToken);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, receivedToken, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedData = response.data?.buildApplication;
                    const documents = loadedData?.documentations;

                    if (Array.isArray(documents) && documents.some(el => el.section === DOCUMENT_FIRST)) {
                        spreadUploadedDocuments(documents, setDocuments, undefined, setUploadedBytes, receivedToken);
                    } else {
                        try {
                            console.log('cover letter documents is non-array or []');
                            setDocuments(prev => ({ ...prev, [DOCUMENT_FIRST]: [] }));
                        } catch (error) {
                            console.log('error', error);
                        }
                    }
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender, secondRender)) {
            return;
        }
        saveChanges();
    }, [request.buildApplication.documentations]);

    useEffect(() => {
        if (newFilesToUpdate.length >= 50000) {
            setUploadError(prev => [...prev, {
                where: null,
                why: 'total_amount',
                uid: null,
            }]);   
            return;
        } else if (newFilesToUpdate.length < 50000 && uploadError.find(el => el.why === 'total_amount')) {
            setUploadError(prev => [...prev].filter(el => el.why !== 'total_amount')); 
        }

        setRequest(prev => ({ ...prev, buildApplication: { ...prev.buildApplication, documentations: [...existingFilesToUpdate, ...newFilesToUpdate].map(file => {
            // eslint-disable-next-line no-unused-vars
            const {blobFile, message, abortController, existing, flagged, ...rest} = file;
            return {...rest};
        }) } }));
    }, [existingFilesToUpdate.length, newFilesToUpdate.length]);

    const createPdf = async () => {
        try {
            const source = axios.CancelToken.source();

            const response = await axios.get(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `preview-pdf/accompanying/${id}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if ((200 <= response?.status) && (response?.status < 300)) {
                console.log('CALL GET PDF', response);
                window.open(URL.createObjectURL(response.data));
            }

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };

    const handleFileInput = async (files, category) => {
        if (category !== DOCUMENT_FIRST) {
            return;
        }

        if (uploadError.find(el => el.why === 'total_amount')) {
            return;
        }
        
        const uploadFile = async (file) => {
            const extractedFileName = extractFileName(file.name);
            const size = file.size;
            const { fileName, uid, url, isDeletable } = await getUrl(token);
            const fileToUpload = {
                uid,
                fileName: fileName || extractedFileName,
                url,
                section: category,
                subSection: 'cover_letter_pdf',
                size,
                blobFile: file,
                message: file.message ?? undefined,
                isDeletable,
                abortController: getNewAbortController(),
            };
            spreadUploadedDocuments(fileToUpload, setDocuments, category, undefined, receivedToken, undefined);
        };

        for (const file of files) {
            await uploadFile(file);
        }
    };

    const uploadErr = React.useMemo(() => allDocuments.some(fileObj => (fileObj.message || fileObj.hasError)), [allDocuments]);
    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (documentMissing || uploadErr) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    return {...stg, error: err };
                }
                return stg;
            }));

            return !err;
        }
    }));

    useEffect(() => {
        if (Number(stepValue) !== Number(stagesArr.length)) {
            return;
        }

        const btn = buttonRef?.current?.finisher ?? null;
        if (error || documentMissing) {
            if (btn) {
                btn.disabled = true;
                btn.classList.add('finisher-disabled');
            }
        } else {
            if (btn) {
                btn.disabled = false;
                btn.classList.remove('finisher-disabled');
            }
        }

        return () => {
            if (btn) {
                btn.disabled = false;
                btn.classList.remove('finisher-disabled');
            }
        };
    }, [error, documentMissing]);

    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 main-title'>{`${stepValue}. Kontrola a podání`}</h2>
                {/* <p className='p-0 kontrola-subtitle'>Stáhněte a zkontrolujte svou žádost.</p> */}
            </Row>
            <Row className='row-wrapper'>
                <p className='p-0 mb-4 kontrola-section__title'>1. Stáhněte, zkontrolujte a digitálně podepište průvodní list</p>
                <button type='button' className='d-flex justify-content-center align-items-center kontrola-button-proj' onClick={createPdf}><img src={file} alt="file" className='kontrola-button-icon' />Stáhnout průvodní list dokumentace</button>
            </Row>

            <Row>
                <p className='p-0 mb-4 kontrola-section__title'>2. Nahrajte podepsaný dokument</p>
                <Row>
                    <Row>
                        <UploadedAttachments
                            category={DOCUMENT_FIRST}
                            attachments={documents[DOCUMENT_FIRST].filter((file, index, self) => {
                                const latestXmlIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'xml');
                                const isXml = extractExtensionName(file.fileName) === 'xml';                
                                if (isXml) {
                                    return index === latestXmlIndex;
                                }
                                return true;
                            })}
                            setUploads={setDocuments} 

                            receivedToken={receivedToken}  
                        />
                    </Row>
                    <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_FIRST} />
                </Row>
                {(error || documentMissing) &&
                <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginBottom: '.5rem'}}>
                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                    Bez nahraného průvodního listu není možné pokračovat
                </Container>}
            </Row>
        </Container>
    );
};

KontrolaComponent18.propTypes = {

    urlPath: PropTypes.string,
    receivedToken: PropTypes.string,
    buttonRef: PropTypes.objectOf(Array),
};

export default KontrolaComponent18;